.container{
    width: 100%;
    height: 60vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: #101010;
}
.container img {
    width: 100vw;
}
.link{
    background-color: #070c07a6;
    position: absolute;
    padding: 0.75vh 4vw;
    border-radius: 50px;
    top: 3%;
    right: 5%;

}
.hover:hover{
    color: #e18620;
}
