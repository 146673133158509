.container{
    /*background-color: orangered;*/
}
.container img{
    width: 2vw;
    filter: invert(100%) sepia(13%) saturate(7500%) hue-rotate(209deg) brightness(115%) contrast(112%);

}
.currentPage{
    border: 1px solid #127010;
    border-radius: 8px;
    user-select: none;
}