.container{
    /*background-color: #0088cc;*/
    height: 100vh;
    position: relative;
}

.container img{
    width: 7.5vw;
    background-color: #E0D8D88C;
    border-radius: 8px
}
.back{
    position: absolute;
    top: 5%;
    text-decoration: none;
    color: #999999;
    left: 3%;
}
.videoFrame{
    width: 60%;
}
.loading{
    height: 100vh;
    width: 100%;
}