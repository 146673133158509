.container{
    flex-wrap: wrap;
}
.itemBox{
    /*display: grid;
    grid-template-columns: 33% 33% 33%;*/
    border-radius: 8px;
    /*border: 1px solid #313131;*/
    /*height: 50vh;
    width: 32%;*/
    margin-bottom: 1.5vh;
    margin-right: 1%;
    /*box-shadow: 0px 0px 13px 0px #ffa6003b;*/
    /*box-shadow: 0px 0px 1px 1px #ffa6003b*/
}
.itemBox:nth-child(3n+1){
    /*margin-right: 0 !important;*/
}
.content{
    height: 75%;
    /*background-color: #000000;*/
    border-radius: 8px 8px 0 0;
    line-height: 0;
}
.content img {
    border-radius: 8px
}
.footer{
    height: 25%;
    font-size: 1.08vw !important;
    /*background-color: #2e2e2e52;*/
    color: #ffffffad;
    border-radius: 0 0 8px 8px;
}