.container {
    margin-bottom: 5vh;
}
.logo {
    width: 15vw;
    height: 25vh;
    border-radius: 8px;
    background-color: #313131;
    position: absolute;
    top: 50vh;
}

.title {
    /*margin-top: 20vh;*/
    margin: 10vh 0 5vh;

}

.wrapper {
    width: 85%;
    height: 18vh;
    border: 1px solid #127010;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;

}

.item {
    /*background-color: orangered;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.item img {
    width: 3vw;
}
.ethereum{
    width: 1.2vw !important;
}

.borderRight {
    border-right: 2px dotted #127010;
}
