.container{
    border-radius: 8px;
    /*border: 1px solid #313131;*/
    /*box-shadow: 0px 0px 13px 0px #ffa6003b;*/
    box-shadow: 0px 0px 1px 1px #ffa6003b;

    /*background-color: white;*/
}
.cardBox:first-child{
    margin-top: 0;
}
.content{
    height: 12vh;

    background-color: #2e2e2e52;
}
.subContent{
    border-radius: 0 0 8px 8px;
    background-color: #9cfd9b94;
    color: #070c07;
    font-weight: 600;
}
.cardBorder{

    border-radius: 8px;
}
.cardWBorder{

    border-radius: 8px 8px 0 0;
}

.content img{

}
.name{
    width: 95%;
    color: #FFFEFEBB;
}
.name img{
    filter: invert(100%) sepia(13%) saturate(7500%) hue-rotate(209deg) brightness(115%) contrast(112%);
}
.arrow{
    width: 5%;
}

.icon{
    /*width: 20%;*/
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    /*background-color: #ffffff85;*/
}
.icon img{
    width: 20vw;
}
.body{
    /*width: 80%;*/
}
.title{
    background-color: #135842;
    color: #fff;
}
.subContentRow{
    border-bottom: 0.003vh solid #25262469;
}
.subContentRow:last-child{
    border-bottom: none;
}