@media (min-width: 992px) {

    html,body{
        font-size: 1.3vw;
        line-height: 5vh;


    }

    .font-size-sm {
        font-size: 0.7vw;
    }
    .font-size-sm-plus {
        font-size: 1.1vw;
    }
    .font-size-md {
        font-size: 1.7vw;
    }
    .font-size-md-01 {
        font-size: 2vw;
    }
    .font-size-md-plus {
        font-size: 2.5vw;
    }
    .font-size-lg {
        font-size: 3vw;
    }

}