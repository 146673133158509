.container{
    width: 85%;
}
.container ul{
    /*border-bottom: 0.001vh solid #f2f2f278;*/
}
.container li{
    /*border: 1px solid #127010;
    border-radius: 8px;
    width: 27%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--whiteAlpha);
    cursor: pointer;*/

    /* border: 1px solid #127010; */
    /*border-radius: 8px;*/
    /* width: 27%; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--whiteAlpha);
    cursor: pointer;
    padding: 1.5vh 2vw;



}
.container li.activeStep{
    color: #127010;
    font-size: 1.4vw;
    font-weight: bold;
    border-bottom: 0.4vh solid #116d10;
}