.container{
    margin-bottom: 5vh;
}
.logo{
    width: 35vw;
    height: 20vh;
    border-radius: 8px;
    background-color: #313131;
    position: absolute;
    top: 50vh;
}
.title{
    margin-top: 5vh;
}
.wrapper{
    width: 80%;
    border: 1px solid #127010;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 50% 50%;
}
.item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.5vh 0;
}
.link{
    /*background: #ed7f0efa;*/
    background: #3590ebfc;
    padding: 1.5vh 7vw;
    border-radius: 8px;
    color: #ffffff;
    /* font-weight: bold; */
    letter-spacing: 1px;
}
.borderRight{
    border-right: 2px dotted #127010;
}
.borderBottom{
    border-bottom: 2px dotted #127010;
}

.ethereum{
    width: 3vw;
}
