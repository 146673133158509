@media (max-width: 480px) {

    html,body{
        font-size: 3.7vw;
        line-height: 3.7vh;
        top: 0;
        bottom: 0;
        position: absolute;
        overflow: hidden;
        font-weight: 300;
        /*background-color: orangered;*/

    }

    .font-size-sm-mini {
        font-size: 2vw;
    }
    .font-size-sm {
        font-size: 3vw;
    }
    .font-size-sm-plus {
        font-size: 3.4vw;
    }
    .font-size-md {
        font-size: 3.75vw;
    }
    .font-size-md-01 {
        font-size: 3.8vw;
    }
    .font-size-md-plus {
        font-size: 4vw;
    }
    .font-size-md-plus-plus {
        font-size: 5vw;
    }
    .font-size-lg {
        font-size: 6vw;
    }
    .font-size-lg-plus {
        font-size: 7vw;
    }



}


@media (min-device-width: 480px) and (max-device-width: 992px) {

    html,body{
        font-size: 3vw;
        line-height: 5vh;
        top: 0;
        bottom: 0;
        position: absolute;
        overflow: hidden;
        font-weight: 300;
        /*background-color: orangered;*/

    }

    .font-size-sm-mini {
        font-size: 1.8vw;
    }
    .font-size-sm {
        font-size: 2.8vw;
    }
    .font-size-sm-plus {
        font-size: 3vw;
    }
    .font-size-md {
        font-size: 3.55vw;
    }
    .font-size-md-01 {
        font-size: 3.6vw;
    }
    .font-size-md-plus {
        font-size: 3.8vw;
    }
    .font-size-md-plus-plus {
        font-size: 4.8vw;
    }
    .font-size-lg {
        font-size: 5.8vw;
    }
    .font-size-lg-plus {
        font-size: 6.8vw;
    }



}






@media screen and (orientation: landscape) {

}