.container{
    /*height: 70vh;*/
}
.inputGroup{
    width: 80vw;
    padding: 3vh 0;
}
.input{
    /*height: 5vh;*/
    width: 100%;

}
.inputGroup input{
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    width: 100%;
    height: 100%;
    border-radius: 8px 0 0 8px;
    border: 2px solid #686868;
    background-color: #30303036;
    color: #b8b5b5;
    font-size: 3.5vw;
    padding: 2vh 3vw;
}
.inputGroup button{
    padding: 2vh 3.5vw;
    background-color: #686868;
    border-radius: 0 8px 8px 0;
    border: 2px solid #686868;
    color: #b8b5b5;
    font-size: 1.4vw;

}
.alert{
    width: 100%;
    font-size: 3.2vw;
    color: #d81414b8;
    text-align: left;
}
.inputGroup img{
    width: 3vw;
}

.frame{
    padding: 5vh 0;
}
.frame img{
    width: 25vw;
    background-color: #E0D8D88C;
    border-radius: 8px
}
.thisClass{

}


