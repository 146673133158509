.container{
    /*background-color: #0088cc;*/
    height: 100%;
    position: relative;
    font-size: 4.3vw

}

.container img{
    width: 25vw;
    background-color: #E0D8D88C;
    border-radius: 8px
}
.back{
    position: absolute;
    top: 3.5%;
    text-decoration: none;
    color: #999999;
    left: 3%;
}
.videoFrame{
    width: 80%;
}
.loading{
    height: 100vh;
    width: 100%;
}