.container{
    width: 80%;
}
.container img{
    width: 100%;
}
@media (min-device-width: 480px) and (max-device-width: 992px) {

    .container{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .container img{
        width: 60%;
    }
}