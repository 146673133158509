.container{
    height: 50vh;
}
.container.result{
    height: 15vh;
}
.inputGroup{
    width: 30vw;
}
.input{
    /*height: 5vh;*/
    width: 100%;

}
.inputGroup input{
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    width: 100%;
    height: 100%;
    border-radius: 8px 0 0 8px;
    border: 2px solid #686868;
    background-color: #30303036;
    color: #b8b5b5;
    font-size: 1.4vw;
    padding: 1.5vh 1vw;
}
.inputGroup button{
    padding: 1.5vh 1vw;
    background-color: #686868;
    border-radius: 0 8px 8px 0;
    border: 2px solid #686868;
    color: #b8b5b5;
    font-size: 1.4vw;

}
.alert{
    width: 100%;
    font-size: 1.2vw;
    color: #d81414b8;
    text-align: left;
}
.inputGroup img{
    width: 1.3vw;
}

.frame{
    padding: 5vh 0;
}
.frame img{
    width: 10vw;
    background-color: #E0D8D88C;
    border-radius: 8px
}

.thisClass{
    width: 64% !important;
}
